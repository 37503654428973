function onFetchInit(data) {
  let form = {}
  data.forEach((e, i) => {
    form['name' + i] = e.name
    form['image2' + i] = e.image2
    form['image3' + i] = e.image3
    form['status' + i] = e.status
  })
  return {
    form,
    typeData: []
  }
}

function onSubmit({ detail, form }) {
  return {
    thirdLoginList: detail.map((e, i) => {
      return {
        ...e,
        name: form['name' + i],
        image2: form['image2' + i],
        image3: form['image3' + i],
        status: form['status' + i]
      }
    })
  }
}

export default {
  type: 'cardForm',
  url: '/config/farmThirdLogin/list',
  edit: '/config/farmThirdLogin/updateBatch',
  onFetchInit,
  onSubmit,
  data: (netData = []) => {
    if (!Array.isArray(netData)) return []
    return (netData || []).map((e, i) => {
      return {
        form: [
          {
            name: '第三方名称',
            type: 'input',
            key: 'name' + i
          },
          {
            name: 'ICON',
            type: 'iconUpload',
            typeData: [
              {
                key: 'image2' + i,
                desc: '@2x   xhdpi <br/>上传尺寸220*384px'
              },
            ]
          },
          {
            name: '显示',
            type: 'switch',
            key: 'status' + i
          }
        ]
      }
    })
  }
}
